<template>
  <div class="home">
    <top></top>
  <div class="center">
    <div class="nav-wrap">
      旗舰店申请
     
 
  
    </div>
  </div>
    <Foo></Foo>
     
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Top from "@/components/login/Top.vue";
import Foo from "@/components/login/Footer.vue";

export default {
  data(){
    return{
      token:""
    }
  },
 name:"Apply",
  components: {
    // HelloWorld
    Top,
    Foo,
 
 
  },
  created(){
  this.gettoken()
  },
  methods:{
        gettoken(){
      this.token =JSON.parse(localStorage.getItem("Login_data"));
     this.$store.state.home.token = JSON.parse(localStorage.getItem("Login_data"));
   
      if(this.token == ''||this.token == null){
           this.$router.push("/login")
      }
    
    }
     
  },
  mounted(){
   

   

  }
}
</script>
<style scoped>
.home{
  
}
.center{
    width: 100%;
    height: 580px;
    /* border: 1px solid red; */
  
}


.nav-wrap{
  border: 1px solid red;
  height: 100%;
  width: 60%;
  margin: auto;
 margin-top: 100px;
 
  
}




</style>
